import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import ContainerDimensions from "react-container-dimensions";
import Vimeo from "@u-wave/react-vimeo";
import {CSSTransition} from "react-transition-group";
import {IconButton} from "./IconButton";


function genCarouselHeight(_contWidth, _rem) {
    const _contRatio = _rem === 18 ? 0.5 : 0.666;
    return (_contWidth * _contRatio) - 2 * _rem;
}


export const Carousel = props => {


    const [swiper, setSwiper] = useState(null);

    const [activeIndex, setActiveIndex] = useState(0);
    const [snapIndex, setSnapIndex] = useState(0);
    const [isEnd, setIsEnd] = useState(0);

    const [direction, setDirection] = useState(false);



    function handleClick(event) {
        if (swiper) {
            const {isBeginning, isEnd} = swiper;
            let _direction;
            if (
                event.clientX - event.currentTarget.getBoundingClientRect().x < props.windowSize.rem * 10
            ) {
                _direction = isBeginning ? "none" : "prev";
            } else if (
                event.clientX - event.currentTarget.getBoundingClientRect().x > props.windowSize.rem * 10
            ) {
                _direction = isEnd ? "none" : "next";
            } else {
                _direction = "none";
            }
            setDirection(_direction);
            if (_direction === "next") {
                swiper.slideNext();
            }
            if (_direction === "prev") {
                swiper.slidePrev();
            }
        }
    }

    function handleMove(event) {
        if (swiper) {
            const {isBeginning, isEnd} = swiper;
            let _direction;
            if (
                event.clientX - event.currentTarget.getBoundingClientRect().x < props.windowSize.rem * 10
            ) {
                _direction = isBeginning ? "none" : "prev";
            } else if (
                event.clientX - event.currentTarget.getBoundingClientRect().x > props.windowSize.rem * 10
            ) {
                _direction = isEnd ? "none" : "next";
            } else {
                _direction = "none";
            }
            setDirection(_direction);
        }
    }


    return (
        <ContainerDimensions>{
            ({width}) =>
                <div className="Carousel-Wrapper">
                    <div
                        className={`Carousel ${direction === "prev" ? "CursorsPrev" : ""} ${direction === "next" ? "CursorsNext" : ""} `}
                        onClick={handleClick}
                        onMouseMove={handleMove}
                    >
                        <Swiper
                            onSwiper={(swiper) => {
                                setSwiper(swiper)
                            }}
                            // onReachEnd={(args) => {
                            //     console.log("reachEnd", args)
                            // }}
                            // onSlideChange={(args) => {
                            //     console.log("onSlideChange", args)
                            // }}
                            // onSnapIndexChange={(args) => {
                            //     console.log("onSnapIndexChange", args.snapIndex)
                            // }}
                            slidesPerView={'auto'}
                            slidesPerColumnFill={'row'}
                            watchSlidesVisibility
                        >
                            {props.media.map((slide, index) =>
                                <SwiperSlide key={'slide_' + index}>
                                    {
                                        ({isActive, isVisible}) => {
                                            if (slide.type === "bild") {
                                                return (
                                                    <Bild
                                                        {...slide}
                                                        isActive={isActive}
                                                        last={index === props.media.length - 1}
                                                        carouselHeight={genCarouselHeight(width, props.windowSize.rem)}
                                                    />
                                                )
                                            } else if (slide.type === "video") {
                                                return (
                                                    <Video
                                                        {...slide}
                                                        isActive={isActive}
                                                        isVisible={isVisible}
                                                        last={index === props.media.length - 1}
                                                        carouselHeight={genCarouselHeight(width, props.windowSize.rem)}
                                                    />
                                                )
                                            }
                                        }
                                    }
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                </div>
        }</ContainerDimensions>
    )
}


const Bild = props => {
    const {image, last, carouselHeight} = props
    const calcedWidth = carouselHeight * (image.width / image.height);
    return (
        <div
            className={`Carousel-Slide Carousel-Slide-Bild ${last ? "last" : ""} ${props.isActive ? "active" : ""}`}
            style={{
                width: calcedWidth,
            }}
        >
            <img
                src={image.urlDesktop}
                alt=""
                width={image.width}
                height={image.height}
            />
        </div>
    );
}


const Video = props => {
    const {last, carouselHeight, previewImage, isActive, isVisible} = props;
    const calcedWidth = carouselHeight * (props.width / props.height);

    const [muted, setMuted] = useState(true)
    const [manualPaused, setManualPaused] = useState(false)


    const handleMute = (e) => {
        e.stopPropagation();
        setMuted(bool => !bool);
    }
    const handleManualPause = (e) => {
        e.stopPropagation();

        setManualPaused(bool => !bool);
    }

    return (
        <div className={`Carousel-Slide Carousel-Slide-Video ${last ? "last" : ""} ${isActive ? "active" : ""}`}>

            <div
                className="Carousel-Slide-IframeWrapper"
                style={{
                    width: calcedWidth,
                }}
            >

                <div className="Carousel-Slide-IframeControls">
                    <IconButton
                        type={manualPaused ? "play-true" : "play-false"}
                        handleClick={handleManualPause}
                    />
                    <IconButton
                        type={muted ? "mute-true" : "mute-false"}
                        handleClick={handleMute}
                    />
                </div>

                <Vimeo
                    background={true}
                    autoplay={true}
                    video={props.videoId}
                    paused={!isVisible || manualPaused}
                    muted={false}
                    volume={muted ? 0 : 1}
                />

            </div>
        </div>
    );
}
