import React, {Component} from 'react';
import Logo from '../static/studiomoono.svg';
import {Link, NavLink} from 'react-router-dom';

export class Header extends Component {

    render() {

        return (
            <div className="Header">

                <div className="container-fluid">
                    <div className="Logo">
                        <Link to="/">
                            <img src={Logo} alt="studio-moono Logo" className="d-block mw-100 mh-100 w-auto h-auto"/>
                        </Link>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-between lead border-bottom border-black px-2">


                                {
                                    this.props.location.pathname === "/about" &&
                                    <div className="text-uppercase hover-green w-50">
                                        <Link to={"/"} className="d-block  w-100">←</Link>
                                    </div>
                                }


                                <NavLink
                                    to={"/about"}
                                    className="d-block hover-green w-100 text-right"
                                    activeStyle={{color: "#000000"}}
                                >
                                    <div className="text-uppercase">About</div>
                                </NavLink>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
