import React, { Component } from 'react';
import {Link} from 'react-router-dom';

export class AboutPage extends Component {
  render() {
    const {data} = this.props;
    return (
      <div className="container-fluid mb-5 About">
            

          { 
            data.about.image.map( image =>
              <div key={image.title} >
                <img src={image.urlDesktop} alt="" className="w-100 d-block border-bottom" />
              </div>
            ) 
          }


          {
            this.props.data.about.columns.map((container,id) => (
              <div key={id} >
                <div className="ItemHeader d-flex justify-content-end lead border-bottom border-black px-2">
                  <div className="text-uppercase">{container.title}</div>
                </div>
                <div className="lead border-bottom px-2 pb-1 redactor" dangerouslySetInnerHTML={{__html:container.body}} />
              </div>
            )) 
          }

          <div className="px-2 lead">
            <Link to="/imprint" className={"hover-green"}>Imprint</Link>
          </div>

      </div>
    );
  }
}
