import React from 'react';

export function Loader (props) {
  return (
    <div className="Loader position-fixed d-flex align-items-center justify-content-center">
      STUDIO MOONO
    </div>
  );
}

export function Crash (props) {
  return (
    <div className="Crash position-fixed d-flex align-items-center justify-content-center">
      Something went wrong. Please try to refresh this webpage.
    </div>
  );
}