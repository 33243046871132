import React, { Component } from 'react';

export class Image extends Component {
  render() {
    
    const {image,style,className} = this.props;

    return (
      <img 
        src={image.urlDesktop} 
        alt="" 
        className={"d-block "+className} 
        style={style} 
        width={image.width}
        height={image.height}
        />
    );
  }
}
