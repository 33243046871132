import React from 'react';
import {Image} from './Image.js';

export const IntroImage = props => {

    const {media} = props;

    if(media.length) return (
        <Image image={media[0].image} />
    );
    else return null;
};