import React, { Component } from 'react';

export class Imprint extends Component {
  render() {
    return (
      <div className="container-fluid mb-5 About">

        <div className="lead border-bottom px-2 pb-1 redactor" dangerouslySetInnerHTML={{__html:this.props.data.imprint}} />

      </div>
    );
  }
}
