import React from 'react';
import axios from 'axios';
import {CSSTransition} from 'react-transition-group';
import {dataUrl} from './constants';
import {Crash, Loader} from './Utils';
import {Routes} from './components/Routes';

const LOADING = 'LOADING';
const COMPLETE = 'COMPLETE';
const ERROR = 'ERROR';


export class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            status: LOADING,
            windowSize: getWindowSize(),
        }
    }

    componentDidMount() {
        axios
            .get(`${dataUrl}/all.json`)
            .then(resp => {
                this.setState({
                    data: resp.data,
                    status: COMPLETE,
                });
            })
            .catch(err => {
                this.setState({
                    status: ERROR,
                });
            });

        window.addEventListener("resize", () => {
            this.setState({windowSize: getWindowSize()});
        });
    }

    render() {
        const {status, data} = this.state;

        return (
            <div
                className="App"
            >
                <CSSTransition
                    in={status === LOADING}
                    timeout={300}
                    classNames="fade"
                    mountOnEnter
                    unmountOnExit
                >
                    <Loader/>
                </CSSTransition>

                <CSSTransition
                    in={status === COMPLETE}
                    timeout={300}
                    classNames="fade"
                    mountOnEnter
                    unmountOnExit
                >
                    <Routes
                        data={data}
                        windowSize={this.state.windowSize}
                    />
                </CSSTransition>

                <CSSTransition
                    in={status === ERROR}
                    timeout={300}
                    classNames="fade"
                    mountOnEnter
                    unmountOnExit
                >
                    <Crash/>
                </CSSTransition>

            </div>
        );
    }

}

export default App;


const getWindowSize = () => {

    let rem, containerHeight;

    if (window.innerWidth > 1199.98) {
        rem = 18;
        containerHeight = window.innerWidth * .5;
    } else if (window.innerWidth > 575.98) {
        rem = 14;
        containerHeight = window.innerWidth * .6;
    } else {
        rem = 11;
        containerHeight = window.innerWidth * .65;
    }

    return {
        width: window.innerWidth,
        height: window.innerHeight,
        rem: rem,
        containerHeight: containerHeight,
    }

}