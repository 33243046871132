import React, { Component } from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {AboutPage} from './AboutPage';
import {Imprint} from './Imprint';
import {ItemList} from './ItemList';
import {Header} from './Header';

export class Routes extends Component {
  render() {
    return (
      <Router>

          {/* if is initial render and pathname="/" redirect to intro  */}
          <Route render={ (route) => <RedirectToIntro {...route} /> } />

          <Route render={ (route) => <Header {...route} /> } />
          
          
          <Switch>
            
            {/* if url fits "/info" render AboutPage */}
            <Route path="/about" render={ (route) => <AboutPage {...route} {...this.props} /> } />
            <Route path="/imprint" render={ (route) => <Imprint {...route} {...this.props} /> } />

            {/* if not "/info" render ItemList and inject a match param if url fits "/:slug" */}
            <Route render={ () =>
              <Route path="/:slug" children={ (route) => <ItemList {...route} {...this.props } />} />}
            />

          </Switch>

      </Router>
    );
  }
}

class RedirectToIntro extends Component {
  componentDidMount() {
    if(this.props.location.pathname==="/"){
      this.props.history.push('/intro');
    }  
  }
  render() {
    return null;
  }
}
