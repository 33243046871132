import React from "react";
import 'swiper/swiper.scss';
import Measure from 'react-measure'
import {IntroImage} from "./IntroImage";


export const Intro = props => {


    return (
        <Measure bounds>
            {({measureRef, contentRect}) =>
                <div
                    className={`Intro ${props.opened ? "opened" : ""}`}
                    style={{height: props.opened ? contentRect.bounds.height : 0 }}
                >
                    <div className={`Intro-Stage`} ref={measureRef}>


                        <div className="Intro-Content">
                            <IntroImage media={props.media} />
                        </div>

                    </div>
                </div>
            }
        </Measure>

    )

}


export default Intro