import React, {Component} from 'react';
import {Item} from './Item.js';
import zeroFill from 'zero-fill';

export class ItemList extends Component {
    render() {
        const {data, windowSize, match, location} = this.props;


        return (
            <div className="container-fluid">
                {
                    data.items.map((item, index) => {
                        const maxLength = Math.max(2, data.items.length.toString().length);
                        const number = zeroFill(maxLength, index) === "00" ? "OO" : zeroFill(maxLength, index);
                        return (
                            <Item
                                key={index}
                                location={location}
                                index={index}
                                number={number}
                                item={item}
                                opened={match && (match.params.slug === item.slug)}
                                windowSize={windowSize}
                            />
                        )
                    })
                }
                <div className="VisitorCount lead px-2 text-uppercase">
                    <span>{window.viewCount}</span>
                    <span className="float-right">Visitors</span>
                </div>
            </div>
        );
    }
}
