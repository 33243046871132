import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Transition} from 'react-transition-group';
import {Element, scroller} from 'react-scroll';
import {Carousel} from './Carousel';
import Measure from 'react-measure'
import Intro from "./Intro"

export class Item extends Component {
    render() {
        const {item, windowSize, opened, number, location} = this.props;
        return (
            <Element name={item.slug}>
                <div className={`Item`}>

                    <ItemHeader opened={opened} {...item} number={number}/>


                    {item.slug === "intro" ?
                        <Intro opened={opened} {...item} />
                        :
                        <ItemContent opened={opened} {...item} windowSize={windowSize} pathname={location.pathname}/>
                    }

                </div>
            </Element>
        );
    }
}


const ItemHeader = props => {
    const {opened, categories, slug, title, number} = props;
    return (
        <Link to={!opened ? slug : "/"}>
            <div
                className={`ItemHeader d-flex justify-content-between lead border-bottom border-black px-2 ${opened ? "opened" : ""}`}>

                <div className="ItemIndex">
                    {number}
                </div>

                <div className="ItemTitle text-uppercase">
                    {title}
                </div>

                <div className="ItemCategories text-uppercase text-right">
                    {categories.join(', ')}
                </div>

            </div>
        </Link>
    );
};


export class ItemContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            info: false,
            height: 0,
        }
        this.scrollToItem = this.scrollToItem.bind(this);
    }

    scrollToItem(_to) {
        if (this.props.pathname !== "/intro") scroller.scrollTo(_to, {duration: 300, smooth: true});
    }

    render() {
        const {opened, slug, itemMeta = null, media, windowSize} = this.props;
        const {info} = this.state;

        return (
            <Measure
                bounds
                onResize={contentRect => {
                    this.setState({...contentRect.bounds})
                }}>
                {({measureRef, contentRect}) => {
                    const {height} = this.state;
                    const transitionStyles = {
                        entering: {height: height},
                        entered: {height: height},
                        exiting: {height: 1},
                        exited: {height: 1},
                    };

                    return (
                        <Transition
                            in={opened}
                            timeout={600}
                            unmountOnExit
                            mountonEnter
                            appear
                            classNames=""
                            onEntered={() => this.scrollToItem(slug)}
                        >
                            {
                                transitionState => (
                                    <div
                                        className={`ItemContentContainer ${info ? "info" : ""} `}
                                        style={{...transitionStyles[transitionState]}}
                                    >
                                        <div className="ItemContent" ref={measureRef}>
                                            {itemMeta && <ItemInfo info={itemMeta}/>}

                                            <Carousel media={media} windowSize={windowSize}/>

                                        </div>
                                    </div>
                                )
                            }
                        </Transition>
                    );
                }
                }
            </Measure>
        );
    }
}


class ItemInfo extends Component {

    state = {
        open: false,
    }

    toggleInfo() {
        this.setState({open: !this.state.open});
    }

    render() {
        const {info = null} = this.props;
        const {open} = this.state;
        return (
            <div className={`ItemInfo clickable ${open ? "open" : ""}`} onClick={this.toggleInfo.bind(this)}>


                <div className="ItemInfoHeader px-2 hover-green">
                    <span className="lead ">INFO</span>
                </div>

                <div className={`ItemInfoBody pl-2 pr-2 pb-2 pt-0 bg-white`}>
                    <div className="mb-3">
                        <span className="lead hover-green">CLOSE</span>
                    </div>
                    <div className={'Redactor'} dangerouslySetInnerHTML={{__html: info.description}}/>
                    <div className={'Redactor'} dangerouslySetInnerHTML={{__html: info.meta}}/>
                </div>


            </div>
        );
    }

}