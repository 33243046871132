import React from "react";

export const IconButton = ({type, handleClick}) => {
    switch (type) {
        case "mute-true":
            return (
                <button className="IconButton" onClick={handleClick}>
                    <MuteTrue/>
                </button>
            );
        case "mute-false":
            return (
                <button className="IconButton" onClick={handleClick}>
                    <MuteFalse/>
                </button>
            )
        case "play-true":
            return (
                <button className="IconButton" onClick={handleClick}>
                    <PlayTrue/>
                </button>
            )
        case "play-false":
            return (
                <button className="IconButton" onClick={handleClick}>
                    <PlayFalse/>
                </button>
            )
        default:
            return null;
    }
}

const PlayTrue = () => (
    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
        <circle cx="17.5" cy="17.5" r="17.5"/>
        <polygon points="27.27 17.54 11.73 27.07 11.73 8 27.27 17.54" style={{fill: "#fff"}}/>
    </svg>
)
const PlayFalse = () => (
    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
        <circle cx="17.5" cy="17.5" r="17.5"/>
        <g>
            <rect x="11" y="9" width="4" height="17" style={{fill: "#fff"}}/>
            <rect x="20" y="9" width="4" height="17" style={{fill: "#fff"}}/>
        </g>
    </svg>
)
const MuteTrue = () => (
    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
        <circle cx="17.5" cy="17.5" r="17.5"/>
        <g>
            <polygon points="17.67 11.25 12.29 14.55 7.19 14.55 7.19 20.45 12.29 20.45 17.67 23.75 17.67 11.25" style={{fill: "#fff"}}/>
            <g>
                <path d="M21.05,20.5a4,4,0,0,0,0-6" style={{fill: "none",stroke: "#fff",strokeMiterlimit: 10,}}/>
                <path d="M23.67,26.5a11.85,11.85,0,0,0,0-18" style={{fill: "none",stroke: "#fff",strokeMiterlimit: 10,}}/>
            </g>
        </g>
    </svg>
)
const MuteFalse = () => (
    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
        <circle cx="17.67" cy="17.5" r="17.5"/>
        <g>
            <polygon points="16.03 11.25 10.65 14.55 5.55 14.55 5.55 20.45 10.65 20.45 16.03 23.75 16.03 11.25" style={{fill: "#fff"}}/>
            <g>
                <line x1="19.95" y1="13" x2="29.45" y2="22.5" style={{fill: "none",stroke: "#fff",strokeMiterlimit: 10,}}/>
                <line x1="19.95" y1="22.5" x2="29.45" y2="13" style={{fill: "none",stroke: "#fff",strokeMiterlimit: 10,}}/>
            </g>
        </g>
    </svg>
)